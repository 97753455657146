import React from 'react'
import { useNavigate } from "react-router-dom";

const MainPageAboutUs = () => {
    const navigate = useNavigate();
    return (
        <div className='aboutus_section'>
            <div className='aboutus_content padding-tb-100'>
                <h3 style={{ margin: '30px' }}><q>
                    HCS India Consultants Pvt. Ltd. is the brainchild of a group of visionaries hailing from diverse fields of expertise, each having forged their own success stories within their respective industries.
                </q></h3>
                <p>

                    We've strategically positioned ourselves within a pivotal niche in the healthcare industry, recognizing its significance. As the journey ahead demands expert opinions and support at every turn, it's evident that numerous hospitals and healthcare providers lack access to a proficient panel of industry pioneers capable of meeting the organization's evolving needs. This deficiency reverberates from the initial idea conception to its eventual realization.
                </p>
                <p>
                    Acknowledging this gap, we embrace the challenge of effecting remarkable transformations.
                </p>

                <div
                      onClick={e=>navigate('/about')}
                    role="button"
                    className={"btn readMore"}
                >
                    <i className={`fa fa-arrow-right me-3`} />
                    Know More
                </div>

            </div>
        </div>

    )
}

export default MainPageAboutUs