import React from "react";
import { managementMembers } from "../../mockData/mockData";
import ManagementCard from "../ManagementCard/ManagementCard";

const Management = () => {
  return (


    <div className="container-xxl py-5 bg-white">
      <div className="container">
        <div
          className="text-center mx-auto mb-5 wow animate__animated animate__fadeInUp" data-wow-delay="0.1s"
          style={{ maxWidth: 600 }}
        >
          <h1 className="d-inline-block py-1 px-4 display-6 red-title">Management Team</h1>
          <figcaption className="black-text mt-1 red-title">
          Empower and train your talented teams for exceptional client service
          </figcaption>
        </div>
        <div className="row g-4 justify-content-center">
          {managementMembers &&
            managementMembers.map((member) => {
              return (
                <ManagementCard data={member} />
              )
            })}
        </div>
      </div>
    </div>


  );
};

export default Management;
