import React from 'react';
import styles from "./Footer.module.scss";
import { CompanyInfo, CopyRightTexts, FooterLinks, SocialLinks } from '../../constants/constants';
import { HashLink } from 'react-router-hash-link';

import hcswImg from '../../assets/HCS-W.png';

function Footer() {
    const renderLinks = ({label,link,type}) => {
        if (link) {
            switch(type) {
                case 'phone':
                    return <a href={`tel:${link}`}>{label}</a>
                case 'email':
                    return <a href={`mailto:${link}`}>{label}</a>
                default:
                    // return <a href={link}>{`${label}`}</a>
                    return <HashLink to={link}>{`${label}`}</HashLink>
            }
        }
        return <span>{label}</span>
    }
  return (
    <div className={styles.wrapper}>
        <div className={styles.links}>
            {
                FooterLinks.map(({title,links})=>(
                    <div className={styles.link}>
                        <h3 className={styles.title}>
                            {title}
                        </h3>
                        <ul>
                            {
                                
                                links.map((link)=>(
                                    <li>
                                        <div className={styles.linkWrapper}>

                                            {renderLinks(link)}
                                        </div>
                                    </li>
                                    
                                ))
                            }
                        </ul>
                    </div>
                ))
            }
        </div> 
        <div className={styles.companyTagline}>
            <img src={hcswImg} alt="company logo" />
            <p>{CompanyInfo}</p>
            <div className={styles.socialLinks}>
                {
                    SocialLinks.map(({img,alt,url})=>(
                        <a href={url} className={styles.socialLink}>
                            <img src={img} alt={alt} />
                        </a>
                    ))
                }
            </div>
        </div>
        <div className={styles.copyright}>
            {
                CopyRightTexts.map(text=>(
                    <div className='textAlignLeft'>{text}</div>
                ))
            }
        </div>
    </div>
  )
}

export default Footer