import React from "react";
import SideLayoutCards from "../components/SideLayoutCards/SideLayoutCards";
import { ServicesDetails } from "../constants/constants";
import ScrollToTop from "react-scroll-to-top";

function Services() {
  return (
    <div className="container-wrapper">
      <ScrollToTop smooth color="#6f00ff" />
      <h2 className="center mainHeading crl-white height-200">Services</h2>
      {ServicesDetails.map(
        ({ text, imgUrl, layout, title, lists, noMargin,id,isReadMore }, idx) => (
          <div
            key={idx}
            class={`item-aboutus width-100 ${noMargin ? "" : "mar-b-100"}`}
          >
            <SideLayoutCards
              lists={lists}
              heading={title}
              layout={layout}
              paragraphs={text}
              image={imgUrl}
              id={id}
              isReadMore={isReadMore}
            />
          </div>
        )
      )}
    </div>
  );
}

export default Services;
