import React from 'react'
import Card from 'react-bootstrap/Card';
import styles from './ProfileCard.module.scss'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const ProfileCard = ({ profileDetails, isDetails = false }) => {
    const cardStyle = {
        border: 'none',
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        // width: '100%',
        // margin:'10px',
        // marginRight:0,
        height: '100%',
        marginBottom: '10px'
    }
    const defaultPic = "/avatar.png";
    let src = process.env.PUBLIC_URL;
    src += profileDetails.imageUrl ? profileDetails.imageUrl : defaultPic;
    return (
        <Card style={cardStyle}>
            {
                isDetails ? (<Row className={'gx-3 ' + styles.rowStyle}>
                    <Col xs={12} md={3}>
                        <img src={src} className={styles.image} alt="profile pic" />
                    </Col>
                    <Col xs={12} md={9} className={styles.content}>
                        <h5>{profileDetails.title}</h5>
                        <h1>{profileDetails.name}
                        </h1>
                        <p> {profileDetails.desc}
                        </p>
                    </Col>
                </Row>) : <Row className={'gx-3 ' + styles.rowStyle}>
                    {/* <Col xs={12} md={3}>
                    <img src={src} className={styles.image} alt="profile pic" />
                </Col> */}
                    <Col xs={12} md={12} className={styles.content}>
                        <h5>{profileDetails.title}</h5>
                        <h1>{profileDetails.name}
                        </h1>
                        <p> {profileDetails.desc}
                        </p>
                    </Col>
                </Row>
            }


        </Card>
    )
}

export default ProfileCard