import React, { useState, useEffect } from "react";
import styles from "./Menu.module.scss";
import { NavigationLinks } from "../../constants/constants";
import { useNavigate, useLocation  } from "react-router-dom";

const Menu = ({closeMenu}) => {
  const [selectedItem, setSelectedItem] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const handleRedirect = ({link,name}) => {
    navigate(link)
    setSelectedItem(name)
  }
  useEffect (()=>{
    setSelectedItem(pathname)
  },[pathname])
  return (
    <div className={styles.menuContainer}>
        <div
          className={styles.closeIconContainer}
          role="presentation"
          onClick={() => closeMenu()}
        >
          <img
            className={styles.closeIcon}
            src={process.env.PUBLIC_URL + "/close.png"}
            alt="menuPngfile"
            width={25}
            height={25}
          />
        </div>
       {NavigationLinks.map((item) => (
        <div
          key={item.name}
          className={
            selectedItem === item.link
              ? styles.selectedMenuItem
              : styles.menuItem
          }
          onClick={e=>handleRedirect(item)}
          role="presentation"
        >
          {item.name}
        </div>
      ))}
    </div>
  );
};

export default Menu;
