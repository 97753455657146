import React from 'react'
import Card from 'react-bootstrap/Card';
import styles from './ProfileCard.module.scss'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import commanderImg from '../../assets/founder1.png'
import { commanderDetails } from '../../constants/constants';

const ProfileCardCommander = () => {
    const cardStyle = {
        border: 'none',
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        // width: '100%',
        // margin:'10px',
        // marginRight:0,
        height: '100%',
        marginBottom: '10px'
    }
    const defaultPic = "/avatar.png";

    return (
        <Card style={cardStyle}>
            <Row className='memorialContainer1'>
                <h2 className='headingstyle wow  animate__animated animate__fadeInUp'>
                    Celebrating the life of Wing Commander (Retd)
                </h2>
                <h1  className='headingstyle wow  animate__animated animate__fadeInUp'> 
                    P K Narendran
                </h1>
                <h6 className='headingstyle wow  animate__animated animate__fadeInUp'>(20/06/1950 to 26/02/2023)</h6>
            </Row>
            <Row className={'gx-3 ' + styles.commanderprofile}>
                <Col xs={12} md={3}>
                    <img src={commanderImg} className={styles.image} alt="profile pic" />
                </Col>
                <Col xs={12} md={8} className={styles.content}>
                    <p> {commanderDetails.desc}
                    </p>
                </Col>
            </Row>

        </Card>
    )
}

export default ProfileCardCommander