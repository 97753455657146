import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NerandranImg from '../../assets/entire_story.jpeg'
import ProfileCardCommander from '../ProfileCard/ProfileCardCommander';

const Memorial = () => {
    return (
        <>
            <Row>
                <ProfileCardCommander />
            </Row>
            {/* <Row className='memorialContainer'>
                <img src={NerandranImg} />
            </Row> */}

        </>

    )
}

export default Memorial