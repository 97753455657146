import React from 'react'
import Button from 'react-bootstrap/Button';
import styles from './button.module.scss'


export const SecondaryButton = ({onClick, label}) => {
  return (
    <Button variant='outline' className={styles.customBtn} onClick={onClick}>{label}</Button>
  )
}
