import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import Contact from "./routes/Contact";
import AboutUs from "./routes/AboutUs";
import "bootstrap/dist/css/bootstrap.min.css";
import HeaderComponent from "./components/HeaderComponent/HeaderComponent";
import Footer from "./components/Footer/Footer";
import Clients from "./routes/Clients";
import Header from "./components/Home/Header/Header";
import Services from "./routes/Services";
import Profile from "./routes/Profile";
import Teams from "./routes/Teams";
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <HeaderComponent />
        <App />
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "contact",
    element: (
      <>
        <Header />
        <Contact />
        <Footer />
      </>
    ),
  },
  {
    path: "about",
    element: (
      <>
        <Header />
        <AboutUs />
        <Footer />
      </>
    ),
  },
  {
    path: "clients",
    element: (
      <>
        <Header />
        <Clients />
        <Footer />
      </>
    ),
  },
  {
    path: "/team",
    element: (
      <>
        <Header />
        <Teams />
        <Footer />
      </>
    ),
  },
  {
    path: "service",
    element: (
      <>
        <Header />
        <Services />
        <Footer />
      </>
    ),
  },{
    path: "profile/:id",
    element: (
      <>
        <Header />
        <Profile />
        <Footer />
      </>
    ),
  }
  
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
