import React from 'react';
import style from './Card.module.scss';

function Card({title,img, text}) {
  return (
    <div className={style.cardWrapper+ ' col-sm-12 col-lg-5 mar-b-100'}>
        <div className={style.imgWrapper}>
            <img className='img-fluid' src={img} alt={title} />
        </div>
        <div className="">
            <h3>{title}</h3>
            <p>{text}</p>
        </div>
    </div>
  )
}

export default Card