import React from "react";
import AccordionItem from "./AccordionItem";

function Accordion({items}) {
  return (
    <div className="custom-accordion bg-white" id="accordionComponent">
    {
        items.map((item,idx)=>(
            <AccordionItem idx={idx} item={item} /> 
        ))
    }
    </div>
  );
}

export default Accordion;
