import React, { useState, useEffect } from "react";
import style from "./SideLayoutCards.module.scss";

function SideLayoutCards({
  layout,
  heading,
  paragraphs = [],
  id,
  lists = [],
  isReadMore,
  image,
}) {
  const [readMore, setReadMore] = useState(isReadMore || false);
  let layoutStyle = { flexDirection: "row" };
  if (layout === "right") layoutStyle = { flexDirection: "row-reverse" };

  const renderListOrSubList = (list, order) => {
    if (typeof list === "string") return <li>{list}</li>;
    if (Object.keys(list).length > 0) {
      if (list.subList) order++;
      return (
        <>
          {renderListOrSubList(list.text,order)}
          {list.paragraph ? <p>{list.paragraph}</p> : null}
          {list.subList ? (
            <ul className={"list"+order}>{list.subList.map((item) => renderListOrSubList(item, order))}</ul>
          ) : null}
        </>
      );
    }
  };

  useEffect(() => {
    console.log("window.location.hash", window.location.hash, "id",id)
    if((window.location.hash==='#conceptualisation' && id==='conceptualisation') ||
    (window.location.hash==='#hospital_commissioning' && id==='hospital_commissioning') ||
    (window.location.hash==='#operational_management' && id==='operational_management'))
    {
      setReadMore(false)
    }
  }, [])
  
  return (
    <>
      {heading ? (
        <div id={id} className="subHeading">
          <strong className={style.contentHeading}>{heading}</strong>
        </div>
      ) : null}
      <div
        className={style.layoutWrapper + " row  gx-5 align-items-center"}
        style={layoutStyle}
      >
        <div className="col-sm-12 col-md-8">
          {paragraphs?.map((para) => (
            <p style={{fontWeight: '500'}}>{para}</p>
          ))}
          {
            lists.length ? (
              <>
                <div className={readMore ? style.hideList : style.showList}>
                  <ul className={style.list}>{lists.map((list) => renderListOrSubList(list,0))}</ul>
                </div>
                {isReadMore ? (
                  <div
                    onClick={(e) => setReadMore(!readMore)}
                    role="button"
                    className={"btn " + style.readMore}
                  >
                    <i className={`fa fa-${readMore?'plus':'minus'}  me-3`} />
                    {readMore ? "Read More" : "Read Less"}
                  </div>
                ) : null}
              </>
            ) : null
          }
        </div>
        <img
          loading="lazy"
          className="img-fluid col-sm-12 col-md-4"
          style={{ height: "360px", objectFit: "contain" }}
          src={image}
          alt=""
        />
      </div>
    </>
  );
}

export default SideLayoutCards;
