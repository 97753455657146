import linkedIn from "../assets/linkedIn.svg";
import facebook from "../assets/facebook.svg";
import instagram from "../assets/instagram.svg";

export const SocialLinks = [
  { img: linkedIn, alt: "linked in logo", url: "https://www.linkedin.com/company/hcs-india-consultants-pvt-ltd/" },
  { img: facebook, alt: "facebook logo", url: "https://www.facebook.com/hcsindiaconsultants?mibextid=ZbWKwL" },
  { img: instagram, alt: "instagram logo", url: "https://www.instagram.com/hcsindiaconsultants?igsh=dWxpOWF1ZHAyd3hq" },
];
export const FooterLinks = [
  {
    title: "Services",
    links: [
      { label: "Conceptualisation", link: "/service#conceptualisation" },
      { label: "Project Management", link: "/service#project_management" },
      { label: "Hospital Commissioning", link: "/service#hospital_commissioning" },
      { label: "Operational Management", link: "/service#operational_management" },
    ],
  },
  {
    title: "Helpful Links",
    links: [
      { label: "Home", link: "/" },
      { label: "About Us", link: "/about" },
      { label: "Services", link: "/service" },
      { label: "Our Team", link: "/team" },
      { label: "Our Clients", link: "/clients" },
      { label: "Contact Us", link: "/contact" },
    ],
  },
  {
    title: "Contact Us",
    links: [
      {
        label: `60/6237, Vettath Complex,
            Chalappuram P. O, Calicut -673002`,
      },
      { label: "+91 9496729872", type: "phone", link: "+91 9496729872" },
      {
        label: "info@hcsindiaco.com",
        type: "email",
        link: "info@hcsindiaco.com",
      },
    ],
  },
];

export const CompanyInfo = `HCS India Consultants Pvt. Ltd. is the brainchild of a panel of visionaries
from different areas of expertise, who have written their own success
stories within their respective industries.`;

export const CopyRightTexts = [
  "2024 © All rights reserved",
  "HCS: 360° Healthcare Consultancy Services provider",
  // "Term & Conditions",
];

export const NavigationLinks = [
  { name: "HOME", link: "/" },
  { name: "ABOUT US", link: "/about" },
  { name: "SERVICES", link: "/service" },
  { name: "OUR TEAM", link: "/team" },
  { name: "OUR CLIENTS", link: "/clients" },
  { name: "CONTACT US", link: "/contact" },
];
export const OurStory = [
  {
    text: [
      `Resources are limited, be it of any kind. 
    The challenge arises when the requirement for the right ingredients arises and no proper 
    allocation is made to make the magic happen. We undertake this task of bridging the gap between 
    those who want to invest in healthcare ventures and those who actually does the construction 
    of hospitals. Our panel of experts always makes it a point to identify the key factors and stick 
    to those elements that would pave way for excellence by the end of the undertaken project. We 
    understand that every patient that approaches a healthcare facility is entitled to receive the best 
    treatment options and this would not be possible without the presence of highly efficient operational 
    systems in the organization. We prioritize the task of minimizing the gap that exists between the 
    actual existing systems with lower clinical efficiency and the targeted systems with ease of operations 
    along with the safety of patients and staff.`,
    ],
    imgUrl: "/our-story1.png",
    title: "Who we are",
  },
  {
    text: [
      `Proper guidance at the right time could make a world of difference in the overall deliverables. We at HCS India provide 360 – degree 
    expertise to ensure that the proper set of clinical principles and guidelines are strictly followed in every aspect, right from the stage of 
    facility planning. With the right policies and procedures in place, quality 
    with efficiency becomes a norm and we make it well-defined and tangible. Conceptualization to Commissioning &Management expertise is the 
    unique value proposition that we offer to our clients.`,
      `HCS India takes up projects, offering comprehensive services, in all streams of medicine including feasibility studies, green field 
    hospital projects, brown field hospital projects – upgradation of the existing facilities and operational management of new and 
    existing hospitals. We take pride in offering comprehensive expertise under one roof in the areas of Concept Designing, 
    Space Planning & Architectural Designs, Medical Equipment Planning & Implementation, Technological adaptation into healthcare, 
    Manpower planning, Recruitment &Training, Commissioning of Hospital Systems, Public Relations & Branding, Accreditations, 
    Healthcare safety and Operational Management of Hospitals.`,
    ],
    imgUrl: "/our-story2.png",
    layout: "right",
  },
];

export const VisionMission = [
  {
    title: "vision",
    text: `Improve the overall quality within the healthcare industry by offering distinctive solutions for project delivery, adhering to the highest standards of quality at every stage.`,
    img: "/vision2.png",
  },
  {
    title: "mission",
    text: `We are committed to upholding stringent quality measures as we fulfill our promise of 360-degree expertise through meticulous planning and service delivery. Our aim is to establish ourselves as the epitome of reliability and professionalism, achieved through imparting knowledge via best-in-class training practices. This dedication reflects in the quality of services and the prevailing satisfaction within the healthcare industry.`,
    img: "/mission.png",
  },
];

export const AssociatesAndPartners = {
  title: "our special associates",
  paragraph:
    "Talented teams need regular training, and empowerment to do the best possible job for the clients we work with.",
  items: [
    {
      title: "Modular Concepts: (Mr. Manoj Dharman)",
      subTitle:
        "Specialists in Civil Construction, Modular Construction, Medical Engineering.",
      paragraph: [
        "Our Infrastructure and Medical Engineering Associate Company. www.modconsllc.com",
        `Medical administration roles are primarily responsible for the efficient 
        administrative operations of a medical facility. We undertake the establishment 
        of clinical protocols, establishment of doctor performance schedule. Commissioning 
        of Emergency Medicine Department, Critical Care Medicine departments, Multidisciplinary 
        ICU, Surgical ICU and Neonatal ICU will be done by us. Commissioning of Labour suites, 
        Operation Theatres, Establishment of OT Committee, all clinical departments, all 
        paraclinical departments, In-patient facilities and Radiology facilities also comes 
        under our expertise. Infection control is made possible by the establishment of 
        infection control department and implementation of IC systems.`,
      ],
    },
    // {
    //   title: "HSJB Business Solutions",
    //   subTitle: "Our Business Development and H R Associates",
    //   paragraph: [
    //     "www.hsjbglobal.com",
    //     `HSJB Business Solutions is a boutique business consulting firm with key focus on performance 
    //     management. HSJB handles business transformation projects focusing on organizational structuring, 
    //     setting up departmental benchmarks and developing and implementing performance metrics. HSJB had 
    //     completed 50+ projects in a span of 2 years from different business verticals such as retail, 
    //     manufacturing and service sectors. HSJB also established other verticals such as hiring and 
    //     contract staffing with established domestic and national clientele. Currently HSJB has got a 
    //     team size of 40+ full time resources in India with India office and Office based at business bay, Dubai.`,
    //   ],
    // },
    {
      title: "TRINCE ONLINE Pvt. Ltd",
      subTitle: "Our General Healthcare and Biomedical Equpt. Associates",
      paragraph: [
        `Trince is a healthcare consultancy who brings in over 20 years of experience in 
        Biomedical Equipment supply, Management, Business, Finance and Strategic Consulting 
        in healthcare Industry.`,
      ],
    },
    // {
    //   title: "Cloudphysician Tele-ICU Solutions",
    //   paragraph: ["wwwcloudphysician.net"],
    // },
    // {
    //   title: "Palladium Power: Solar Energy Provider",
    //   paragraph: [
    //     `Palladium designs and delivers customer-specific energy solutions that are environmentally 
    //     benign and meet the most modern design and delivery standards. We cater to a wide array 
    //     of professional services from concept to commissioning in the field of power and energy 
    //     solutions for small to large business enterprises including power utilities. Palladium 
    //     is a leading energy solutions and services company operating in India with headquarters 
    //     at Cochin, Kerala. The company is promoted by a group of successful entrepreneurs and has 
    //     collaborations with leading multinational companies. With decades of experience, Palladium 
    //     offers a comprehensive professional and cost competitive energy solutions and services for 
    //     power and energy sector. Our professional services and partnerships in the energy and power 
    //     business have enabled many organisations to cut cost and become more effective, efficient and 
    //     green in the sourcing and using of energy/power services.`,
    //   ],
    // },
    {
      title: "RCM Assist Healthcare Solutions Pvt. Ltd",
      paragraph: [
        `In the Niche area of Healthcare Insurance, RCM Assist facilitates Revenue Cycle Management, 
        liaison, and Consultancy services for Operations of Different cashless schemes /credit schemes.`,
      ],
    },
  ],
};

export const ServicesArray = [
  {
    title: "Conceptualisation",
    desc: `We offer Concept Detailing and Architect Selection services, translating visions into tangible designs vetted by professionals. Our focus on optimizing Architectural Designs ensures efficient space management. We strategically place clinical departments to enhance workflow. As liaisons, we facilitate seamless communication between you and the architect, ensuring alignment. Our approach blends expertise and creativity to transform concepts into vibrant, functional spaces.`,
    linkLabel: "Our pharma and biotech services ",
    active: true,
    favIcon: "fa-users-cog",
    link: "/service#conceptualisation",
  },
  // {
  //   title: "Design and Engineering",
  //   desc: `Structural and Civil engg. Consultancy and Contracting work.MEP(including STP),Fire and Environmental Safety Consulting and Contracting work.Consulting and contracting for OTs and Medical gases.Interior Design and contracting.`,
  //   linkLabel: "Our pharma and biotech services ",
  //   active: false,
  //   favIcon: "fa-building",
  //   link:'/service#design_and_engineering'
  // },
  {
    title: "Project Management",
    desc: `Our project management approach starts with a comprehensive 'Project Charter', detailing costs, tasks, deliverables, and schedules. We monitor each vendor's work execution until completion, ensuring adherence to timelines and quality standards. Timely identification of potential problems allows for swift resolution, minimizing disruptions and ensuring smooth progress.`,
    linkLabel: "Our pharma and biotech services ",
    active: false,
    favIcon: "fa-tasks",
    link: "/service#project_management",
  },
  {
    title: "Hospital Commissioning",
    desc: `We orchestrate the setup of essential departments like Biomedical Engineering, Operations, Medical Administration, Nursing, Finance, Pharmacy, Clinical Laboratory, Blood Bank, CSSD, and Radiology. Our meticulous coordination ensures seamless integration, operational efficiency, and compliance. From infrastructure to protocols, each element is managed for top-tier patient care and operational excellence.`,
    linkLabel: "Our pharma and biotech services ",
    favIcon: "fa-cubes",
    link: "/service#hospital_commissioning",
  },
  {
    title: "Operational Management",
    desc: `To revolutionize Facility Operation Systems, we prioritize peak efficiency, service excellence, and profitability. Through rigorous Gap Analysis by our subject matter experts (SMEs), we unveil critical insights. Subsequently, we deploy a multipronged Action Plan, meticulously tailored for optimal operational outcomes. Our approach ensures not just improvement, but transformation, driving unparalleled success in every facet of operation.`,
    linkLabel: "Our pharma and biotech services ",
    active: false,
    favIcon: "fa-laptop-code",
    link: "/service#operational_management",
  },
];

export const ServicesDetails = [
  {
    title: "Conceptualisation",
    text: [
      "HCS Helps the client develop the clinical concept of the proposed Healthcare facility taking into consideration various prevailing circumstances.",
      // `HCS (Healthcare Services) plays a crucial role in guiding clients through the development of 
      // the clinical concept for a proposed healthcare facility. This process involves a comprehensive 
      // examination of various factors and prevailing circumstances to create a well-defined and 
      // purposeful clinical framework.`
    ],
    isReadMore: true,
    imgUrl: "./conceptualization.png",
    lists: [
      "Development of appropriate clinical concept",
      "Selection of the most suited architect with relevant experience",
      "Finalization of the concept at the level of functioning of each clinical department to incorporate in the Hospital design",
      {
        text: "Vetting of the design proposed by the architect:",
        subList: [
          "Vetting of the Architectural design for optimized space management, as per NBC, and NABH Guidelines and all other required Standards",
          "For proximity of departments for operational efficiency, customer convenience and emergency & safety requirements",
        ],
      },
      {
        text: "Advice the architect for micro planning of each clinical department to analyse and finalise the space requirements based on infra structural requirements like:",
        subList: [
          "Appropriate furniture",
          "Electrical network",
          "Medical Gas Piping System (MGPS)",
          "Medical equipment etc.",
        ],
      },
    ],
    // noMargin:true
    id: "conceptualisation",
  },
  {
    title: "Project Management",
    imgUrl: "./project.png",
    layout: "right",
    lists: [
      "Prepare and review the work schedule to ensure the realistic timeline of work completion for budget & resource control.",
      "Preparation of ‘Project Charter’ including costs, tasks, deliverables, and work schedules.",
      "Monitoring work execution by each contracted vendor till completion, weekly contractor’s meeting to monitor progress and resolve issues faced if any.",
      "Identification of potential problems in a timely manner and initiation of corrective measures during the project timeline as per the Engineering diagram.",
    ],
    id: "project_management",
  },
  {
    title: "Hospital Commissioning",
    text: [
      `Commissioning of a hospital envisages the task of functionalising all the clinical, paramedical and support departments. Experts in the 
    HCS panel of consultants will see that each department is fully functional before the hospital is fit to take in patients. The following 
    are the main areas requiring focussed attention during commissioning.`,
    ],
    isReadMore: true,
    imgUrl: "http://hcsindiaco.in/wp-content/uploads/2022/04/PR-1024x1024.png",
    lists: [
      {
        text: "Biomedical Engineering Department.",
        subList: [
          "Preparation of list of all medical equipment-speciality wise.",
          "Preparation of the neutral technical specifications of the required equipment.",
          "Tender analysis and preparation of techno- commercial comparison.",
          "Follow up on the purchases and co-ordination of installation processes as per the pre-determined schedule.",
          "Supervision of the installation process.",
          "Training of the end users : Make sure that the vendor / manufacturer gives appropriate training to the end users.",
        ],
      },
      {
        text: "Operations Department",
        subList: [
          "Preparation of SOPs for all the operational systems",
          "Tariff fixation of all the services and entry into the HIS (Hospital Information System)",
          "Billing system and related communications",
          "Entire Customer care plan implementation (Front office management & Feedback management)",
          {
            text: "Setting up of",
            subList: [
              "OP and IP Processes",
              "House Keeping systems",
              "Security systems",
              {
                text: "F & B  (Food & Beverages) services by focusing on Kitchen commissioning and establishing operational policies",
                subList: [
                  "Therapeutic kitchen establishment",
                  "Patient food management",
                  "Public canteen management",
                ],
              },
            ],
          },
          {
            text: "Establishment of",
            subList: [
              "Internal and external transport systems and protocols",
              "General Duty Assistants team and setting up trolley and wheelchair protocols",
              {
                text: "Waste management protocols",
                subList: [
                  "Biomedical waste management",
                  "STP (Sewage Treatment Plant) management systems",
                  "WTP (Water Treatment Plant) management systems",
                ],
              },
            ],
          },
          "Agreements with outsourcing agencies",
          {
            text: "Establishment of Supply Chain Management systems",
            subList: [
              "Purchase department",
              "General Stores",
              "Pharmacy purchasing",
              "OT stores",
            ],
          },
        ],
      },
      {
        text: "Human Resources",
        paragraph: `Human resource management is a key function within the hospital. We focus on the implementation of the 
      organizational chart with clear chain of command and reporting structure and well defined roles and responsibilities.`,
        subList: [
          "Preparation of manpower recruitment schedules in discussion with the client",
          "Preparation of job description for all categories of employees",
          "Recruitment of Doctors, Nursing staff, Technicians and General staff",
          {
            text: "Comprehensive management of the recruitment process for all staff",
            subList: [
              "Advertisement of vacancies",
              "Short listing prospective candidates",
              "Organizing the interview process with panel of relevant experts",
            ],
          },
          "Preparation of employees’ handbook",
          {
            text: "Training for the staff members",
            subList: [
              "Staff induction training",
              "Ongoing on-the-job training",
              "Soft skills training",
            ],
          },
          "Statutory affairs",
          "Staff welfare systems implementation",
          "Establishment of HR policies in accordance with NABH Standards",
          "Establishment of credentialing and privileging processes",
          "HRMS implementation",
          "Payroll management",
        ],
      },
      {
        text: "ITC (Information Technology and Communications)",
        paragraph: `We have experts in our team with expertise in`,
        subList: [
          "IT hardware commissioning",
          "Selection of the software",
          "Implementation of the HIS (Hospital Information System) and EMR (Electronic Medical Records)",
          "Internal communication systems",
          "Establishment of Call Centre",
          "IP Phone systems and IPBX",
          "CCTV systems",
          "Public address system",
          "Various display systems",
          "Videoconferencing systems",
        ],
      },
      {
        text: 'Branding, PR & Marketing',
        paragraph: `This is a key area often overlooked by the clients. It is very important that this 
        forms part of the commissioning activities and efforts shall start well before the full launch of the project.`
      },
      {
        text: 'Medical Administration',
        paragraph: `Medical administration is primarily responsible for the efficient administrative operations of clinical 
        departments. This part of the Hospital Commissioning process takes care of the following:`,
        subList: [
          'Establishment of clinical protocols',
          {
            text: 'Commissioning of',
            subList: [
              'Emergency Medicine Department',
              {
                text: 'Critical Care Medicine departments',
                subList: [
                  'Multidisciplinary ICU',
                  'Surgical ICU',
                  'Neonatal ICU'
                ]
              },
              'Labour suites',
              'Operation Theatres'
            ]
          },
          {
            text: 'Establishment of',
            subList: [
              'OT Committee',
              'All clinical departments',
              'All paraclinical departments',
              'In-patient facilities',
              'Radiology facilities',
              'Infection control systems'
            ]
          }
        ]
      },
      {
        text: 'Nursing',
        paragraph: `Nursing is the largest and most important department in a hospital. Intense efforts 
        shall go into the establishment of an efficient Nursing Department. The following are the key factors:`,
        subList: [
          'Establishing the nursing department protocols',
          'Recruitment and Training',
          'Establishing high quality patient-oriented care practices',
          'Ensuring that nurses are highly efficient and motivated in their work',
          'Nursing duty allocation policies',
          'Nurse education policies'
        ]
      },
      {
        text: 'Finance',
        paragraph: `Proper Finance management is the backbone of any industry. Healthcare industry has its own 
        nuances in finance management. Our panel of experts shall work on establishing efficient financial management systems.`,
      },
      {
        text: 'Pharmacy',
        paragraph: 'Our panel of Pharmacy experts put in thorough efforts towards the:',
        subList: [
          'Establishment and licensing of OP Pharmacy and IP Pharmacy',
          'Preparation of drug formulary of the hospital',
          'Establishment of Pharmacy inventory control systems',
          'Establishment of Pharmacy and Therapeutic Committee'
        ]
      },
      {
        text: 'Clinical Laboratory establishment',
        paragraph: `Laboratory Medicine is an important clinical division of a hospital with multi-faceted impacts in patient care and also in the profitability 
        of the institution. Our team of experts set up the clinical laboratory of the hospital suiting the clinical concept of the hospital.
        The following acts are crucial events:`,
        subList: [
          'Commissioning of the equipment and running QC (Quality Control)',
          'Establishing quality control systems',
          'NABL Lab standards and guidelines and documentation processes',
          'Internal lab audits',
          'Statutory licenses',
          'Tariff fixation',
          'Creating a good Test Portfolio as per clinical and market survey with a plan for launch of new tests',
          'Providing assistance in Lab consumption data',
          'Test wise cost Metrics and implementation of the same',
          {
            text: 'Activities involving the streamlining of Lab:',
            subList: [
              'Material consumption monitoring',
              'Stock management',
              'Involvement in vendor finalization in coordination with client',
              'Marketing programmes of the laboratory',
              'Conceptualization and preparation of all the required literature/Brochure to pursue local clients in terms of business conversion',
              'Training and Development of the Lab Staff',
              'Liaising and coordinating with key internal departments to provide best customer service and TAT (Turn Around Time) experience within Lab'
            ]
          }
        ]
      },
      {
        text: 'Quality Assurance Department',
        paragraph: 'Quality Assurance Department is the most important department in a hospital ensuring healthcare quality and patient safety.'
      }
    ],
    id: "hospital_commissioning",
  },
  {
    title: "Operational Management",
    imgUrl: "./operations.png",
    id: "operational_management",
    isReadMore: true,
    layout: "right",
    text: [`HCS takes up the: `,
    `- Operational management of hospitals post commissioning and also of the existing hospitals, with an offer to run 
    the hospital profitably.`,
    `- Preparation of the business plan based on the clinical concept and vision of the owner(s).`,
    `- Financial control: Monthly review of financial performance of each department against the revenue budget projected`,
    `The following departments will come under the purview of the Operational Management.`],
    lists: [
      "Human Resources Management",
      "Finance and accounts department",
      "Information Technology and Communication",
      "Operations Department",
      "Medical Administration",
      "Nursing Department",
      "Pharmacy systems",
      "Clinical Laboratory systems",
      "Infection Control programme",
      "Functioning of all the support departments",
      "Branding, Public Relations (PR) and Marketing",
      "Quality Assurance Department"
      // "Operations management",
      // "Feasibility study & SWOT analysis",
      // "Conceptualization of the clinical structure of the hospital",
      // `Lead & coordinate a detailed evaluation and finalization of 
      // the concept of level of functioning of each clinical department 
      // to be incorporated in the Hospital design`,
    ],
  },
  // {
  //   imgUrl:
  //     "http://hcsindiaco.in/wp-content/uploads/2022/06/About-Us-1-1024x1024.png",
  //   lists: [
  //     "Operations Department",
  //     "Human Resources",
  //     "ITC (Information Technology and Communications)",
  //     "Branding & PR",
  //     "Marketing",
  //     "Medical Administration",
  //     "Nursing",
  //     "Finance",
  //     "Pharmacy",
  //     "Clinical Laboratory establishment",
  //     "Setting up of Blood bank/Blood storage facility",
  //     "Establishment of CSSD (Central Sterile Supply Department)",
  //     "Radiology",
  //     "Quality Assurance",
  //   ],
  //   layout: "right",
  // },
  // {
  //   title: "Operations Department",
  //   imgUrl:
  //     "http://hcsindiaco.in/wp-content/uploads/2020/08/Operational-Business-min-1024x1024.png",
  //   text: [
  //     `We focus on the preparation of SOPs for all the operational systems, Tariff fixation of all 
  //     the services and entry into the HIS, Billing system and related communications, Entire Customer 
  //     care plan implementation (Front office management, Feedback management), Setting up OP processes, 
  //     Setting up IP Processes, Setting up House Keeping systems and operational SOPs,  Setting up Security 
  //     systems and operational SOPs, Setting up F & B services by focusing on Kitchen commissioning and establishing 
  //     operational policies, Therapeutic kitchen establishment, F & B supply and maintenance systems, 
  //     Patient food management, Public canteen management. We also handle the establishment of internal and 
  //     external transport systems and protocols, establishing General Duty Assistants team and setting up trolley 
  //     and wheelchair protocols, Waste management protocols, Biomedical waste management, STP management systems 
  //     implementation and WTP management systems. Our team also establishes agreements with outsourcing agencies, 
  //     establishment of Supply Chain Management systems which includes Purchase department and committees, General 
  //     Stores and inventory control, Pharmacy purchasing systems and OT stores.`,
  //   ],
  // },
  // {
  //   title: "ITC (Information Technology & Communications) Department",
  //   imgUrl:
  //     "https://hcsindiaco.in/wp-content/uploads/2022/05/ITC-min-1024x1024.png",
  //   text: [
  //     `We have experts in our team to help in IT hardware commissioning,selection of the software, implementation of the HIS 
  //     (Hospital Information System) and EMR (Electronic Medical Records), internal communication systems, establishment of Call 
  //     Centre, IP Phone systems and IPBX, CCTV systems,public address system, various display systems,videoconferencing systems.`,
  //   ],
  // },
  // {
  //   title: "Medical Administration",
  //   imgUrl:
  //     "https://hcsindiaco.in/wp-content/uploads/2022/05/Medical-admin-1-min-1024x1024.png",
  //   layout: "right",
  //   text: [
  //     `Medical administration roles are primarily responsible for the efficient administrative operations of a medical facility. 
  //     We undertake the establishment of clinical protocols, establishment of doctor performance schedule. Commissioning of Emergency 
  //     Medicine Department, Critical Care Medicine departments, Multidisciplinary ICU, Surgical ICU and Neonatal ICU will be done by us. 
  //     Commissioning of Labour suites, Operation Theatres, Establishment of OT Committee, all clinical departments, all paraclinical 
  //     departments, In-patient facilities and Radiology facilities also comes under our expertise. Infection control is made possible 
  //     by the establishment of infection control department and implementation of IC systems.`,
  //   ],
  // },
  // {
  //   title: "Nursing",
  //   imgUrl:
  //     "https://hcsindiaco.in/wp-content/uploads/2020/08/Nursing-min-1024x1024.png",
  //   text: [
  //     `Establishing the nursing department protocols, Recruitment and Training, establishing high quality patient-oriented care practices, 
  //     Ensuring that nurses are highly efficient and motivated in their work, Nursing duty allocation policies and Nursing education policies 
  //     will be accomplished by our team of experts.`,
  //   ],
  // },
  // {
  //   title: "Pharmacy",
  //   layout: "right",
  //   imgUrl:
  //     "https://hcsindiaco.in/wp-content/uploads/2020/08/Pharmacy-min-1024x1024.png",
  //   text: [
  //     `Our HCS team undertakes the establishment and licensing of OP Pharmacy and IP Pharmacy, Preparation of drug formulary of the hospital, 
  //     Pharmacy inventory control, Establishment of Pharmacy and Therapeutic Committee.`,
  //   ],
  // },
  // {
  //   title: "Clinical Laboratory Medicine Department",
  //   imgUrl:
  //     "http://hcsindiaco.in/wp-content/uploads/2020/08/clinical-Laboratory-min-1024x1024.png",
  //   noMargin: true,
  //   text: [
  //     `We also perform the commissioning of the equipment and running QC, establishing quality control systems, 
  //     Establish NABL Lab standards and guidelines and documentation processes, Internals lab audits and NC closure 
  //     management, Statutory licenses, Tariff fixation, creating a good Test Portfolio as per clinical and market 
  //     survey with a plan for launch of new tests, providing assistance in Lab consumption data, test wise cost 
  //     Metrics and implementation of the same.`,
  //     `Activities involving the streamlining of Lab material consumption, stock management, involvement in 
  //     vendor finalization in coordination with client, marketing programmes of the lab, conceptualization 
  //     and prepare all the required literature/Brochure/LBL/Folders to pursue local clients in terms of 
  //     business conversion, Training and Development of the Lab Staff and IC Team, liaising and coordinating 
  //     with the key internal departments to provide best customer service and TAT experience within Lab are also done by us.`,
  //   ],
  // },
];

export const Facilities = [
  {
    title: "Blood Bank/Blood Storage Facility",
    text: `We design blood bank based on the statutory norms, coordinate the statutory licensing process, 
    develop transfusion medicine policies and organize blood donation campaigns.`,
    img: "",
  },
  {
    title: "Central Sterile Supply Department",
    text: `Our panel of experts in HCS establishes the Three Zone functioning of the CSSD, supervise the 
    installation, commissioning and quality control of the sterilizers, establish CSSD policies and plan 
    for academic programmes.`,
    img: "",
  },
  {
    title: "Quality Assurance Department",
    text: `Ensuring Quality Assurance in the hospital helps in improving clinical outcomes. We focus on 
    the establishment of a good Quality Assurance Department (QAD), Preparation of policies and protocols 
    for all departments and functions in the hospital, Initiation of the establishment of all the Quality 
    policies in line with future accreditation, Training of all employees in line with future accreditation, 
    Implementation of quality audit systems, Establishment of various mandatory committees and Implementation 
    of all statutory licenses.`,
    img: "",
  },
  {
    title: "Biomedical Engineering Department",
    text: `We engage in Medical Equipment Planning & Deployment, Preparation of list of all medical equipment-specialty 
    wise, according to space planning based on optimum requirement of each department.Preparation of the neutral 
    technical specifications of the required equipment, tender analysis and preparation of techno- commercial 
    comparison follow up on the purchases and co-ordination of installation processes as per the pre-determined 
    schedule, supervision of the installation process, training of the end users shall also be done by us. We shall 
    also make sure that the vendor/manufacturer gives appropriate training to the end users identified.`,
    img: "",
  },
  {
    title: "Radiology Department",
    text: `We handle the commissioning of the radiology equipment, statutory licensing procedures and marketing of Radiology.`,
    img: "",
  },
  {
    title: "Supporting Medical Departments",
    text: `We at HCS, also focus on establishing and managing supporting medical departments like Engineering & Maintenance 
    Department and Public Relations Department.`,
    img: "",
  },
  {
    title: "General Administration",
    text: `We establish Administration and Office Guidelines, Office Hours, Absences and Lateness, Attendance, Dress code, 
    Bulletin Board, Computer software (Unauthorized copying), Copyright/Ownership issues, Representing the Organization, 
    Personal phone calls, Gifts and Soliciting, Office Security and using office equipment.`,
    img: "",
  },
];

export const profiles = [
  {
    id: "dr-p-mohanakrishnan",
    title: "CHAIRMAN & MANAGING DIRECTOR",
    name: "DR. P. MOHANAKRISHNAN",
    desc: `Dr P Mohanakrishnan started his career as an ENT Surgeon after completing his MBBS and PG in Otolaryngology.
    He also attained an MBA in Healthcare Services and has expanded his interests into Healthcare Administration.
    Having held various leadership roles across major hospitals in Kerala and GCC,
    he is a well-known healthcare professional in South India and carries a great expertise in establishing hospitals,
    commissioning hospitals, project planning, strategic planning, quality improvement and successful hospital management.`,
    pointersTitle: "PROJECTS COMPLETED BY DR P MOHANAKRISHNAN:",
    pointersList: [
      "Institute of ENT and Head & Neck Surgery, Kozhikode (Concept to Commissioning & as Managing Partner)",
      "Aster MIMS Hospital, Kozhikode (Commissioning & as Hospital Director)",
      "Aster Hospital for Maternity & Surgery, Dubai (Commissioning & as Medical Director)",
      "Medcare Hospital, Dubai (Concept to Commissioning)",
      "Aster Hospital, Muscat, Oman (Conceptualization, design and project execution as Hospital Consultant)",
      "Aster Hospital, Sohar, Oman (Conceptualization, design and project execution as Hospital Consultant)",
      "Aster MIMS Hospital, Kottakkal (Design Consultant & CEO)",
      "Magrabi Eyecare centre, Dubai Healthcare City (Design review and supervision: 2006)",
      "The Atlantis, Palm Jumeirah Dubai (Poly Clinic Design for more than 7000 construction employees: 2006 – 2007)",
      "Kokoori Institute of Fertility and Laparoscopy Kozhikode, Kerala, India (Design and Construction Consultancy: 2015)",
      "Malabar Cancer Centre, Thalassery, Kerala, India (Radiology Dept. and Laboratory Design consultancy, along with ULCCS: 2015-16)",
      "Coop. Hospital Vadakara, Kerala, India (Gap Analysis and Consultancy for procurement of NABH accreditation: 2016)",
      "Priam Family Healthcare India Private Limited (Concept to & as Director& CEO)",
      "Avitis Institute of Medical Sciences, Nenmara, Palakkad (Conceptualization, Project Management and Commissioning & as CEO).",
      "Ascent ENT Speciality Centre, Dubai, UAE",
    ],
    imageUrl: "/potrait_1.jpg",
  },
  {
    id: "jincy-geo",
    title: "EXECUTIVE DIRECTOR",
    name: "MS. JINCY GEO",
    desc: `Having attained significant experience in the field of Human Resources Management, 
    especially within Healthcare Industry, Jincy has remarkable expertise in the entire processes of Recruitment, 
    Staff Training & Welfare Management and the proper implementation of HR policies.`,
    pointersTitle:
      "JINCY WAS INSTRUMENTAL IN THE SUCCESSFUL COMPLETION OF THE FOLLOWING HOSPITAL PROJECTS:",
    pointersList: [
      "Meitra Hospital, Kozhikode, Kerala, India (Greenfield project – Implementation of the entire HR systems)",
      "Avitis Institute of Medical Sciences, Nenmara, Palakkad, Kerala, India (Project – Implementation of the entire HR systems)",
      "Indiana Hospital, Mangalore, Karnataka, India (Monitoring of the entire HR systems as HR Head)",
      "KIMS Al Shifa Hospital, Perinthalmanna, Kerala, India (Monitoring of the entire HR systems as HR Head).",
    ],
    imageUrl: "/potrait_2.jpg",
  },
  {
    id: "dr-sukumaran-vengail",
    title: "DIRECTOR – CLINICAL AFFAIRS",
    name: "DR. SUKUMARAN VENGAIL",
    desc: `Dr Sukumaran Vengail, is a Senior Consultant Surgeon and Professor of Surgery with more than 25 years of clinical and administrative experience in Healthcare in India and abroad.
     He is an eminent Laparoscopic Surgeon and Academician. He is a Senior Healthcare Management Professional, trained and certified by IIM, Kozhikode.                                                                                   He is well known for his objective and result oriented interventions at various clinical and operational interfaces of the day-to-day operations of hospitals. He has ample experience in setting up and launching healthcare organisations. His areas of interest include quality oriented patient services, patient delight, clinical services branding, segmentation and positioning, which aims at business success in the shortest possible time. Strategizing healthcare business is his special focus in which he has had some remarkable achievements to his credit.                                                         As Director, Clinical Affairs he supervises the implementation and commissioning of all the clinical departments in a hospital.`,
    pointersTitle:
      "DR. SUKUMARAN VENGAIL HAS HANDLED THE FOLLOWING HEALTHCARE CLIENTS:",
    pointersList: [
      "RAK Hospital, Ras Al Khaimah, UAE (Brand Launch & PR Consultant: 2009)",
      "Yemen International Hospital, Yemen (Crisis Management PR Consultant: 2011 – 12)",
      "Badr Al Sama Healthcare Group, Sultanate of Oman (Branding & PR Consultancy: 2011)",
      "KIMS Al Shifa Hospital, Perinthalmanna, Kerala, India (Monitoring of the entire HR systems as HR Head: 2020–2021).",
      "Columbia Asia Hospital, Bangalore, Karnataka, India (Branding & PR Consultancy: 2015 - ongoing)",
      "Avitis Institute of Medical Sciences, Nenmara, Palakkad, Kerala, India (Brand Launch & PR Consultant: 2017 - ongoing)",
      "Cimar Hospital & Fertility Centre, Kochi, Kerala, India (Branding & PR Consultancy: 2018 – 19)",
      "Meitra Hospital, Kozhikode, Kerala, India (Branding & PR Consultancy: 2020 – 21)",
      "CRAFT Hospital and Research Centre, Kodungallur, Kerala, India and in Maldives (Branding & PR Consultancy: 2021 - ongoing)",
      "Able Cure Healthcare, Changaramkulam, Kerala, India (Branding & PR Consultancy: 2021 - ongoing).",
    ],
    imageUrl: "/potrait_4.jpeg",
  },
  {
    id: "dr-sathyajith-ramvihar",
    title:
      "DIRECTOR OF QUALITY, LABORATORY SERVICES, AND INFECTION CONTROL MANAGEMENT",
    name: "DR. SATHYAJITH RAMVIHAR",
    desc: `Dr Sathyajith, an established eminent Clinical Microbiologist, has completed his MBBS and MD in Clinical Microbiology. His competence in Infection Control made him a passionate Infection Control Physician, a role which is of paramount importance in all hospitals. He is also adept in establishing Clinical Laboratories in hospitals and has served as the Head of the Department of Clinical Laboratory Medicine. 
    He has experience in establishing and coordinating all clinical departments in a start-up hospital.`,
    pointersTitle: "PROJECTS HANDLED BY DR. SATHYAJITH RAMVIHAR:",
    pointersList: [
      "Avitis Institute of Medical Sciences, Nenmara, Palakkad, Kerala, India (Greenfield conceptualization and project execution of Laboratory Medicine department as Consultant and Head of the Department)",
      "Avitis Institute of Medical Sciences, Nenmara, Palakkad, Kerala, India (Infection Control processes – Implementation Consultant)",
      "Dr Scan Diagnostics, Kozhikode (NABL Accreditation Consultant – 2020)",
      "Malabar Medical College, Kozhikode, Kerala (Laboratory Consultant & NABL Accreditation Consultant – 2020)",
      "Seventh Day Adventists’ Hospital, Ottapalam, Kerala, India (Laboratory Consultant & NABL Accreditation Consultant – 2021)",
      "Avitis Institute of Medical Sciences, Nenmara, Palakkad, Kerala, India (Brand Launch & PR Consultant: 2017 - ongoing)",
      "KIMS Al Shifa Hospital, Perinthalmanna, Kerala, India (Laboratory Medicine & Infection Control Consultant - 2021)",
      "Trinity Diagnostics, Palakkad, Kerala, India (Greenfield conceptualization and project execution Consultant – 2021).",
    ],
    imageUrl: "/potrait_3.jpg",
  },
  {
    id: "sreenivasan-v-p",
    title: "DIRECTOR – FACILITY MANAGEMENT",
    name: "MR. SREENIVASAN V P",
    desc: `Sreenivasan has more than 40 years of experience in healthcare management. He is an expert in Operation Theatre technology and Sterilization technology. 
    He has been heading the Operation theatre complex and Central Sterile Supply Department (CSSD) in many major hospitals like Wokhardt Hospital, Fortis Hospitals, Narayana Hrudayalaya, 
    Armed Forces Hospital in Muscat and Avitis Institute of Medical Sciences. He was an integral part of the team which set up Avitis Institute of Medical Sciences, Nenmara, Palakkad.
    He started his career in Indian Airforce paramedical servuces. Over the years of his healthcare experience, he has become an expert in Hospital Operations and is quite adept in planning the hospitals to suit the operational requirements.
  
    At HCS he looks after the planning of Operation Theatre Complexes, CSSD and in operational planning of the hospitals.`,
    pointersTitle: "SREENIVASAN V P WAS INVOLVED IN THESE PROJECTS:",
    pointersList: [
      "Aster MIMS Hospital, Kozhikode, Kerala (Deputy Manager, Quality Assurance Department Instrumental in getting NABH accreditation in 2006 as the first multispecialty hospital in India to be accredited by NABH)",
      "Kokoori Institute of Fertility and Laparoscopy, Kozhikode, Kerala (Consultant in Healthcare quality)",
      "Priam Family Healthcare, Kozhikode, Kerala (Consultant in Healthcare quality)",
      "Starcare Hospital, Kozhikode, Kerala (Consultant in Healthcare quality)",
      "Parco Hospital, Vadakara, Kerala (Consultant in Healthcare Quality and Operations as Deputy General Manager).",
    ],
    imageUrl: "/potrait_5.jpeg",
  },
];

export const serviceOptions = [
  // "Pre-Designing, Designing & Engineering",
  "Project Management",
  "Commissioning Of Hospital Systems",
  "Operations Department",
  "Human Resource Department",
  "ITC Department",
  "Medical Administration",
  "Nursing",
  "Finance",
  "Pharmacy",
  "Clinical Laboratory Medicine Department",
  "Blood Bank/Blood Storage Facility",
  "Central Sterile Supply Department",
  "Radiology Department",
  "Quality Assurance Department",
  "Supporting Medical Departments",
  "Biomedical Engineering Department",
  "General Administration",
  "Public Relations, Marketing & Branding",
];

export const PanelConsultants = [
  {
    id: "consultant_healthcare_finance",
    title: "Consultant-Healthcare Finance",
    name: "Mr. Ashwin Simon",
    // imageUrl: "/ashwin_simon.png",
    // desc: `Qualified as a Chartered Accountant at the age of 23, Ashwin has over 13 years of experience in the field of Accounting,
    // Audit and Taxation. Having completed post qualification courses such as ISA, Certified Concurrent Auditor, and Certificate
    // Course in GST; he is also a Fellow Member of the Institute of Chartered Accountants of India. His areas of expertise
    // ranges from Financial and Tax Advisory, Systems Audit, Internal Audits, Concurrent Audits, Financial consultancy in the
    // fields of Healthcare, Education and various other fields.`
    desc: `Ashwin Simon, a well-established Chartered Accountant, has over 15 years of experience in the field of Healthcare 
    Finance, Accounting, Audit and Taxation. Having completed post qualification courses such as ISA, Certified Concurrent 
    Auditor, and Certificate Course in GST; he is also a Fellow Member of the Institute of Chartered Accountants of India. 
    He leads a team of experts in Financial consultancy in the Healthcare field.`,
  },
  {
    id: "oncology_consultant",
    title: "Oncology Consultant",
    name: "Mr. Sridharan Parthasarathy",
    // desc: `Sridharan is an Engineering Graduate from The University of Madras and a Lean and Six Sigma Certified Healthcare
    // Operations Professional With 33 Years of Experience in Sales, Service & Project P&L across South Asia, ASEAN and Americas.
    // Handled P & L of upto US$75M and a Team Size of 1400. He is a Certified Auditor for ISO 13485 standard. He brings with him
    // close to 3 decades of Multinational Operations Experience in Delivering Large Projects in Radiology, Cardiology, Molecular
    // Imaging and Radiation Therapy Functions.`
    desc: `Sridharan is an Engineering Graduate from The University of Madras and a Lean and Six Sigma Certified Healthcare 
    Operations Professional With 33 Years of Experience in Sales, Service & Project P&L across South Asia, ASEAN and Americas. 
    Handled P & L of up to US$75M and a Team Size of 1400. He is a Certified Auditor for ISO 13485 standard. He brings with 
    him close to 3 decades of Multinational Operations Experience in Delivering Large Projects in Radiology, Cardiology, 
    Molecular Imaging and Radiation Therapy Functions.`,
  },
  // {
  //   id: 'operations_consultant',
  //   title:'Operations Consultant',
  //   name:'DR. Hari Sivadas',
  //   desc:`Strategic Healthcare Professional | Growth Catalyst | Business Strategist| International exposure across Middle
  //   East & Indian Sub-Continent Proven track record of implementing Healthcare Management strategies while steering
  //   processes and policies for an effective and efficient Hospital System, targeting assignments in Strategic Healthcare
  //   Management/ Operations/ Project Strategic Professional with 29 years of experience in developing & executing Quality
  //   Hospital Administration Strategies to accomplish revenue growth, formulated long-term plans & SOPs to enhance
  //   operations; spearheaded cost saving initiatives in various processes.`
  // },
  // {
  //   id: 'safety_and_quality_consultant',
  //   title:'Safety and Quality Consultant',
  //   name:'Dr Unni Meladi',
  //   desc:`Dr Unni has more than a dozen years’ experience as a Healthcare Consultant. He is an Expert on and conducts result
  //   oriented training programs on Health, Safety, Environment and Management subjects. Personal Safety, Training &
  //   Development are his forte.`
  // },
  // {
  //   id:'his_it_consultant',
  //   title:'HIS (I T) Consultant',
  //   name:'Nanda Kumar M K',
  //   desc: `N K Has over 25 years of IT leadership experience including oversight of Infrastructure, Application support and
  //   security services. He is Proficient in: Project lifecycle management,• Quality assurance, Security Planning,
  //   Infrastructure Development, Requirement Analysis, Asset Management, Budget administration and Documentation Management.`
  // },
  // {
  //   id:'healthcare_administration_and_operations_consultant',
  //   title:'Healthcare Administration and Operations Consultant',
  //   name:'Suresh Babu',
  //   desc: `A professional with proven work experience of 28 yrs (14+ yrs outside India). He has specialised expertise in the
  //   areas of “Operational aspects of Organisation” such as effective implementation of Policies in HR Management, Administration,
  //   Procurement & Logistics, Financial Controls etc., in Healthcare Sector (Hospitals, Medical College, Nursing & Para Medical Colleges).`
  // },
  // {
  //   id: "qa_accreditations_consultant",
  //   title: "Q A & Accreditations Consultant",
  //   // imageUrl: "/swathy_lakshmi.png",
  //   name: "Swathy Lakshmi",
  //   // desc:`With more than 15 yrs of dynamic experience to her credit, S L is an Internal Auditor for NABH,
  //   // National assessor for NQAS ( National Quality Assurance Standard for Public health facilities) and Auditor for ACE program for CSSD.`
  //   desc: `With more than 15 years of dynamic experience to her credit, Swathy is an Internal Auditor for NABH, National assessor for 
  //   NQAS ( National Quality Assurance Standard for Public health facilities) and Auditor for ACE program for CSSD. She has been 
  //   instrumental in getting NABH accreditation for many hospitals.`,
  // },
  {
    id: "biomedical-consultant",
    title: "Biomedical Consultant",
    name: "Mr. Vinod Nair",
    desc: `Vinod Nair is an Electronics and Communication Engineer specialized in
    Bio Medical Engineering with more than 30 years of operational
    experience in the Medical Instrumentation Technology sector.
    An experienced Biomedical Engineer with a track of working with the
    healthcare industry in various hospital Projects, Bio Medical Consultancy,
    Medical Equipment Maintenance & Medical Equipment Training.
    Vinod Nair has got immense experience collaborating with Multinational
    companies, mainly in the Radiology sector.
    He is actively involved in many projects, looking after the Medical
    Instrumentation areas of our company.`
  },
  //{
  //   id:'modular_concepts',
  //   title: 'Specialists In Civil Construction, Modular Construction, Medical Engineering.',
  //   name:'Manoj Dharman',
  //   desc: `Our Infrastructure and Medical Engineering Associate Company. www.modconsllc.com
  //   Medical administration roles are primarily responsible for the efficient administrative 
  //   operations of a medical facility. We undertake the establishment of clinical protocols, 
  //   establishment of doctor performance schedule. Commissioning of Emergency Medicine Department, 
  //   Critical Care Medicine departments, Multidisciplinary ICU, Surgical ICU and Neonatal ICU will 
  //   be done by us. Commissioning of Labour suites, Operation Theatres, Establishment of OT Committee, 
  //   all clinical departments, all paraclinical departments, In-patient facilities and Radiology 
  //   facilities also comes under our expertise. Infection control is made possible by the establishment of 
  //   infection control department and implementation of IC systems.`
  // },
  // {
  //   id:'trince_online_pvt',
  //   title: 'Our General Healthcare And Biomedical Equpt. Associates',
  //   desc:`Trince is a healthcare consultancy who brings in over 20 years of experience in Biomedical Equipment 
  //   supply, Management, Business, Finance and Strategic Consulting in healthcare Industry.`
  // },
  // {
  //   id:'cloudphysician_tele',
  //   title: "Cloudphysician Tele-ICU Solutions",
  //   desc: "wwwcloudphysician.net",
  // },
  // {
  //   id: 'rcm_assist_healthcare_solution',
  //   title: "RCM Assist Healthcare Solutions Pvt. Ltd",
  //   desc:`In the Niche area of Healthcare Insurance, RCM Assist facilitates Revenue Cycle Management, 
  //     liaison, and Consultancy services for Operations of Different cashless schemes /credit schemes.`,
  // }
];

export const commanderDetails = {
  desc: `We firmly believe that Narendranji's lasting presence serves as our guiding beacon.
   He was our unwavering source of wisdom, reliably assisting us in overcoming any challenge. 
   His profound insights into life have left an indelible mark on us. As a mentor,
    he has not only influenced our professional trajectories but also left an imprint on our personal growth. 
    His pragmatic approach to problem-solving has played a pivotal role in shaping our character and achievements.
     Narendranji stands as the epitome of HCS, 
  continuously empowering us to honor our commitments to clients and society at large.`
}


