export const mockFeatures = [
  {
    title: "The HCS Way",
    content:
      "We combine vast industry expertise, scientific curiosity, and     laser-sharp business instincts to partner with you in leading     advances n the life sciences.Together, we bridge scientific    innovation into winning business strategy...",
    buttonLabel: "Learn More",
    imageUrl: "/compassImg.jpg",
    navigationUrl: "",
    alignment: "left",
  },
  {
    title: "Connectivity",
    content:
      "HCS Healthcare Partners, a strategy consulting firm    focused in the life sciences, announces the opening of    two new offices in London and Zurich, which will strengthen    the company’s partnerships with both European-based...   ",
    buttonLabel: "Learn More",
    imageUrl: "/worldMapImg.jpg",
    navigationUrl: "",
    alignment: "right",
  },
  {
    title: "Our Mission",
    content:
      "HCS Healthcare Partners, a strategy consulting firm    focused in the life sciences, announces the opening of    two new offices in London and Zurich, which will strengthen    the company’s partnerships with both European-based...   ",
    buttonLabel: "Learn More",
    imageUrl: "/doctorImg.jpg",
    navigationUrl: "",
    alignment: "left",
  },
];

export const managementMembers = [
  {
    name: "Dr. P Mohanakrishnan",
    designation: "Chairman & Managing director",
    description:
      "  Dummy text follows - t is a long established fact that a reader will be distracted by the readable content of a page  when looking  its layout. The point of using Lorem Ipsum  is that it has a more-or-less normal distribution of letters...",
    imageUrl: "/potrait_1.jpg",
    alignment: "left",
    id: "dr-p-mohanakrishnan"
  },

  {
    name: "Ms. Jincy Geo",
    designation: "Executive director",
    description:
      "  Dummy text follows - t is a long established fact that a reader will be distracted by the readable content of a page  when looking  its layout. The point of using Lorem Ipsum  is that it has a more-or-less normal distribution of letters...",
    imageUrl: "/potrait_2.jpg",
    alignment: "right",
    id:"jincy-geo"
  },

  {
    name: "Dr. Sathyajith Ramvihar",
    designation:
      "Director of Quality, Laboratary  Services and Infection control management",
    description:
      "  Dummy text follows - t is a long established fact that a reader will be distracted by the readable content of a page  when looking  its layout. The point of using Lorem Ipsum  is that it has a more-or-less normal distribution of letters...",
    imageUrl: "/potrait_3.jpg",
    alignment: "left",
    id:"dr-sathyajith-ramvihar"
  },

  {
    name: "Dr. Sukumaran Vengail",
    designation: "Director - Clinical Affairs",
    description:
      "  Dummy text follows - t is a long established fact that a reader will be distracted by the readable content of a page  when looking  its layout. The point of using Lorem Ipsum  is that it has a more-or-less normal distribution of letters...",
    imageUrl: "potrait_4.jpeg",
    alignment: "left",
    id:"dr-sukumaran-vengail"
  },

  {
    name: "Mr. Sreenivasan V P",
    designation: "Director - Facility Management",
    description:
      "  Dummy text follows - t is a long established fact that a reader will be distracted by the readable content of a page  when looking  its layout. The point of using Lorem Ipsum  is that it has a more-or-less normal distribution of letters...",
    imageUrl: "/potrait_5.jpeg",
    alignment: "right",
    id:"sreenivasan-v-p"
  },
];
