import React, { useEffect, useState } from 'react'
import Card from 'react-bootstrap/Card';
import client5 from '../assets/client5.jpeg';
import ProfileCard from '../components/ProfileCard/ProfileCard';
import { profiles } from '../constants/constants';
import { useParams } from 'react-router-dom';




const Profile = () => {
  const params = useParams()
  const [profileName, setProfileName] = useState(params?.id || '')
  const profileDetails = profiles.find((profile) => profile.id === profileName)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  console.log("profileDetails", profileDetails)
  return (
    <div className='p-20'>
      {!profileDetails ? <div>Profile not found</div> :
        <>
          <ProfileCard profileDetails={profileDetails} isDetails={true}/>
          <h5 className='profile-pointers'>{profileDetails.pointersTitle}</h5>
          <ul className='profile-pointers'>
            {
              profileDetails.pointersList?.map(pointer => {
                return (
                  <li><span class="">{pointer}</span></li>
                )
              })
            }
          </ul>
        </>
      }
    </div>

  )
}

export default Profile