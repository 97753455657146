import React from "react";
import { PanelConsultants } from "../constants/constants";
import ProfileCard from "../components/ProfileCard/ProfileCard";

function PanelConsultant() {
  return (
    <div className="row panelWrapper">
      {PanelConsultants.map((panel) => (
        <div
          data-wow-delay="0.8s"
          className="col-sm-12 col-md-6 wow  animate__animated animate__fadeInUp mb-3"
        >
          <ProfileCard profileDetails={panel} />
        </div>
      ))}
    </div>
  );
}

export default PanelConsultant;
