import React from 'react'
import styles from './Servcies.module.scss'
import { ServicesArray } from '../../../constants/constants'
import { HashLink } from 'react-router-hash-link';

const Services = () => {
    return (
        <>
            {/* Service Start */}
            <div className={`container-xxl py-5 ${styles.serviceBlockContainer} overlayDiv`} >
                <div className="container">
                    <div
                        className={`text-center mx-auto mb-5 wow  animate__animated animate__fadeInUp ${styles.bgcolor}`}
                        data-wow-delay="0.1s"
                        style={{ maxWidth: 700 }}
                    >
                        {/* <p className="d-inline-block border rounded-pill py-1 px-4">Services</p> */}
                        <h1 className='mainHeading' style={{ 'font-size': '40px'}}>Health Care Solutions</h1>
                    </div>
                    <div className="row g-4">
                        {
                            ServicesArray && ServicesArray.map((service) => {
                                return <div className="col-lg-6 col-md-6 wow animate__animated animate__fadeInUp" data-wow-delay="0.1s" key={service.title}>
                                    <div className="service-item bg-light rounded h-100 p-5">
                                        <div
                                            className="d-inline-flex align-items-center justify-content-center bg-white rounded-circle mb-4"
                                            style={{ width: 65, height: 65 }}
                                        >
                                            <i className={`fa ${service.favIcon} ${styles.bgcolor}  fs-4`} />
                                        </div>
                                        <h4 className="mb-3">{service.title}</h4>
                                        <p className={`mb-4 ${styles.desc}`}>
                                            {service.desc}
                                        </p>
                                        <HashLink to={service.link}>
                                            <div className="btn" >
                                                <i className={`fa fa-plus  me-3 ${styles.bgcolor}`} />
                                                Read More
                                            </div>
                                        </HashLink>
                                    </div>
                                </div>
                            })

                        }
                    </div>
                </div>
            </div>
            {/* Service End */}
        </>

    )
}

export default Services