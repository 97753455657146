import { useEffect, useState, useRef } from "react";
import style from "./Accordion.module.scss";

function AccordionItem({ idx, item }) {
  const [arrowDown, setArrowDown] = useState(false);
  const collapseRef = useRef(null);

  const handleButtonClick = () => {
    let classList = collapseRef?.current?.classList || null;
    if (classList?.contains("show")) {
      classList?.remove("show");
      setArrowDown(false);
    } else {
      classList?.add("show");
      setArrowDown(true);
    }
  };
  return (
    <div className={style.accordionWrapper + " "}>
      <div className={style.header}>
        <button
          className="accordion-button blue-title"
          type="button"
          onClick={handleButtonClick}
          //   data-bs-toggle="collapse"
          //   data-bs-target={`#collapse${idx}`}
          aria-expanded="true"
          aria-controls={`collapse${idx}`}
        >
          {item.title}
        </button>
        <div className={style.icon}>
          <i class={`fas ${arrowDown ? "fa-arrow-up" : "fa-arrow-down"}`}></i>
        </div>
      </div>
      <div
        id={`collapse${idx}`}
        ref={collapseRef}
        className={`accordion-collapse collapse`}
        aria-labelledby={`heading${idx}`}
        data-bs-parent="#accordionComponent"
      >
        <div className="accordion-body">
          {item.subTitle && <div className="subHeading blue-title">{item.subTitle}</div>}
          {item?.paragraph?.map((para) => (
            <p>{para}</p>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AccordionItem;
