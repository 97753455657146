import React, { useState, useRef } from 'react'
import { AssociatesAndPartners, OurStory, VisionMission } from '../constants/constants'
import SideLayoutCards from '../components/SideLayoutCards/SideLayoutCards'
import Card from '../components/Card/Card'
import Accordion from '../components/Accordion/Accordion';
import ScrollToTop from "react-scroll-to-top";
import { serviceOptions } from '../constants/constants';
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { Toast } from "react-bootstrap";


const Contact = () => {
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [company, setCompany] = useState('')
  const [message, setMessage] = useState('')
  const [showToast, setToast] = useState('')
  const [servicesSelected, setService] = useState([])
  const [emailError, setemailError] = useState(false)

  const multiselectRef = useRef(null);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const isFormReady = () => {
    return name?.length > 2 && phone?.length > 9
  }

  const clearForm = () => {
    setName('')
    setPhone('')
    setEmail('')
    setService([])
    setMessage('')
    setCompany('')
    resetValues();
    return
  }

  const submitRequest = async () => {
    const isValidemail = validateEmail(email);
    if (email && !isValidemail) {
      setemailError(true)
      return
    }
    const response = await fetch('https://formsubmit.co/ajax/9dc624a5b0f91bca103adaa59fc54406', {
      method: 'POST',
      headers: {
        Accept: 'application.json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name,
        phone,
        message,
        email,
        company,
        servicesSelected
      })
    })
    if (response.status === 200) {
      setToast(true)
    }
    console.log(JSON.stringify({
      name,
      phone,
      message,
      email,
      company,
      servicesSelected
    }))
    clearForm()
  }

  const handleOnChange = (selected) => {
    setService(selected);
  }

  const resetValues = () => {
    // By calling the belowe method will reset the selected values programatically
    if(multiselectRef?.current?.state?.selected) {
      multiselectRef.current.state.selected = [];
    }  
  }

  return (
    <div className='container-wrapper-contact text-primary-color'>
      <ScrollToTop smooth color="#6f00ff" />
      <h2 className="center mainHeading crl-white height-200">Contact Us</h2>
      <div id="contact" className="container-xxl py-5 nav-section overlay center bg-white">
        <div className="container">
          <div className="text-center wow animate__animated animate__fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title text-center text-uppercase text-primary-color">
              Get in touch
            </h6>
          </div>
          <div className="container-sm">
            <div className="text-center mt-2 mb-2">
              <h2 className="">Let us reach out to <span className="text-uppercase">YOU</span></h2>
            </div>
            <div className="d-flex justify-content-evenly pt-2 text-align-left  wow animate__animated animate__fadeInUp row">
              <div className="col-xs-12 col-md-5">
                <label className="">Name <span style={{ color: 'red' }}>*</span></label> <br />
                <input type="text" name="" value={name} onChange={(e) => { setName(e.target.value) }} id="" className="form-control" />
              </div>
              <div className="col-xs-12 col-md-5">
                <label className="">Phone <span style={{ color: 'red' }}>*</span></label> <br />
                <input type="number" maxlength="10" value={phone} onChange={e => {
                  if(e.target.value.length==11) return false
                  else setPhone(e.target.value)
                }} className="form-control" />
              </div>
            </div>
            <div className="d-flex justify-content-evenly pt-2 mt-2 text-align-left  wow animate__animated animate__fadeInUp row">
              <div className="col-xs-12 col-md-5">
                <label className="">Email <span style={{ color: 'red' }}>*</span></label> <br />
                <input type="email" name="" id="" value={email} onChange={e => setEmail(e.target.value)} className="form-control" />
              </div>
              <div className="col-xs-12 col-md-5">
                <label className="">Company Name <span style={{ color: 'red' }}>*</span></label> <br />
                <input type="text" name="" id="" value={company} onChange={e => setCompany(e.target.value)} className="form-control" />
              </div>
            </div>
            <div className="d-flex justify-content-evenly pt-5 mt-2  wow animate__animated animate__fadeInUp row">
              <div className="col-8">
                {/* <Dropdown>
                  <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic" >
                    {serviceSelected}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {
                      serviceOptions.map(seriveOption => {
                        return <Dropdown.Item  onClick={() => setService(seriveOption)}>{seriveOption}</Dropdown.Item>
                      })
                    }
                  </Dropdown.Menu>
                </Dropdown> */}
                <DropdownMultiselect options={serviceOptions} handleOnChange={handleOnChange} placeholder="Select one/more options" ref={multiselectRef} />
              </div>
            </div>

            <div className="d-flex justify-content-evenly pt-2 mt-2 text-align-left  wow animate__animated animate__fadeInUp row">
              <div className="col-xs-12 col-md-8">
                <label className="">Message</label> <br />
                <input type="text" name="" id="" value={message} onChange={e => setMessage(e.target.value)} className="form-control" />
              </div>
            </div>

            <div className="text-center mt-3">
              <button className="btn btn-primary btn-blue" disabled={!isFormReady()} onClick={submitRequest}>Submit</button>
            </div>

          </div>
        </div>
      </div>
      <Toast onClose={() => setToast(false)} show={showToast} animation={true} delay={3000} autohide>
        <Toast.Header>Response Recorded</Toast.Header>
        <Toast.Body>Thank you for reaching out. Our team will be in touch with you shortly</Toast.Body>
      </Toast>
      <Toast onClose={() => setemailError(false)} show={emailError} animation={true} delay={3000} autohide>
        <Toast.Header>Invalid Input</Toast.Header>
        <Toast.Body>Please enter the correct email</Toast.Body>
      </Toast>

    </div>
  )
}

export default Contact