import React from "react";
import Header from "./Header/Header";
import styles from "./Home.module.scss";
import Feature from "../Feature/Feature";
import Career from "../Career/Career";
import Management from "../Management/Management";
import ValuestatementBlock from "../../components/Home/ValueStatements/ValuestatementBlock";
import Services from "./Services/Services";
import MainPageAboutUs from "./MainPageAboutUs";
import Memorial from "../Management/Memorial";

const HomeComponent = () => {
  return (
    <>
      {/* <div className={styles.homeContainer}>
        <Header />
        <div className={styles.backgroundImageContainer}>
          <img
            className={styles.backgroundImage}
            src={process.env.PUBLIC_URL + "/steth.png"}
            alt="pngfile"
          />
        </div>
        <h1 className={"display-1 "+ styles.maintag}>One step solution <br /> for healthcare</h1>
        <div className={styles.visionContainer}>
          <div className={styles.label}>our vision</div>
          <div className={styles.visionDesc}>
            enhance overall quality within the healthcare industry by providing
            unique solutions for the delivery of projects with highest standards
            of quality in every step
          </div>
          <div className={"p-1 " + styles.statContainer}>
            <div className={styles.statItem}>
              <div className={styles.statValue}>20+</div>
              <div className="lead">years of experience</div>
            </div>
            <div className={styles.statItem}>
              <div className={styles.statValue}>95%</div>
              <div className="lead">patient satisfaction rating</div>
            </div>
            <div className={styles.statItem}>
              <div className={styles.statValue}>5000+</div>
              <div className="lead">patients served annually</div>
            </div>
            <div className={styles.statItem}>
              <div className={styles.statValue}>10+</div>
              <div className="lead">
                healthcare providers on staff
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className={styles.featureContainer}>
        {mockFeatures &&
          mockFeatures.map((featureItem) => {
            return <Feature data={featureItem} />;
          })}
      </div> */}
      <ValuestatementBlock />
      <MainPageAboutUs />
      <Services />
      {/* <Career /> */}
      <Memorial />
      <Management />
    </>
  );
};

export default HomeComponent;
