import React from 'react'
import Header from '../Home/Header/Header'
import styles from "../Home/Home.module.scss";
import ScrollToTop from 'react-scroll-to-top';

function HeaderComponent() {
  return (
    <div className={styles.homeContainer}>
      <ScrollToTop smooth color="#6f00ff" />
        <div
          className={styles.backgroundImageContainer}
          // style={{
          //   backgroundImage: "url(" + process.env.PUBLIC_URL + "/steth1.png)",
          // }}
        >
          <Header />
          <div className={`${styles.maintag} wow animate__animated animate__fadeInLeft`}   data-wow-delay="0.5s"> One stop solution for healthcare</div>
          <div className={styles.visionContainer}>
            <div className={styles.label}>Your partner in redefining healthcare deliverables</div>
            {/* <div className={styles.visionDesc}>
            
            </div> */}
          </div>
          {/* <div className={"p-2 " + styles.statContainer}>
            <div className={styles.statItem}>
              <div className={styles.statValue}>20+</div>
              <div className={styles.lead}>years of experience</div>
            </div>
            <div className={styles.statItem}>
              <div className={styles.statValue}>95%</div>
              <div className={styles.lead}>patient satisfaction rating</div>
            </div>
            <div className={styles.statItem}>
              <div className={styles.statValue}>5000+</div>
              <div className={styles.lead}>patients served annually</div>
            </div>
            <div className={styles.statItem}>
              <div className={styles.statValue}>10+</div>
              <div className={styles.lead}>
                healthcare providers on staff
              </div>
            </div>
          </div> */}
        </div>
      </div>
  )
}

export default HeaderComponent