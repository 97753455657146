import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { SecondaryButton } from '../../reusableComponents/SecondaryButton/SecondaryButton';
import visionImg from '../../../assets/vision.png'
import missionImg from '../../../assets/mission.png'
import hcsway from "../../../assets/hcsway.png"
import styles from './ValueStatement.module.scss'
import Card from 'react-bootstrap/Card';
const ValuestatementBlock = ({ index }) => {

  const valueStatements = [
    {
      img: hcsway,
      heading: "The HCS Way",
      content: `Drawing upon extensive industry expertise, scientific curiosity, and razor-sharp business instincts, we collaborate with you to spearhead advancements in the life sciences. 
      Together, we seamlessly integrate scientific innovation with winning business strategies...`
    },
    {
      img: visionImg,
      heading: "Our vision",
      content: `Improve the overall quality within the healthcare industry by offering distinctive solutions for project delivery, adhering to the highest standards of quality at every stage.`
    },
    {
      img: missionImg,
      heading: "Our Mission",
      content: `We are committed to upholding stringent quality measures as we fulfill our promise of 360-degree expertise through meticulous planning and service delivery. Our aim is to establish ourselves as the epitome of reliability and professionalism, achieved through imparting knowledge via best-in-class training practices. 
      This dedication reflects in the quality of services and the prevailing satisfaction within the healthcare industry.`
    }
  ]
  const cardStyle = {
    border: 'none',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    width: '90%',
    margin: '30px',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '30px',
}
  return (
    <div className={styles.valueBlockContainer}>
      {
        valueStatements.map((statement, index) => {
          const style = {display: 'flex', flexDirection : index %2 == 0 ? "row": "row-reverse"}
         return(
          <Card style={cardStyle} className='wow  animate__animated animate__zoomIn'>
            <Row className={styles.row } style={style}>
              <Col md={7} xs={12}>
                <div className={styles.content}>
                  <h5 className={styles.contentHeading}>{statement.heading}</h5>
                  <div className={styles.contentBody}>{statement.content} </div>
                  {/* <SecondaryButton onClick={() => { console.log("click") }} label="Learn More" /> */}
                </div>

              </Col>
              <Col md={5} xs={12} >
                <img src={statement.img} height="300px" style={{'object-fit': 'contain'}} className={styles.valuesImage} />
              </Col>

            </Row>
            </Card>)

        })
      }

    </div>
  )
}

export default ValuestatementBlock