import { useEffect } from "react";
import "./App.scss";
import "animate.css";
import WOW from "wowjs";
import HomeComponent from "./components/Home/Home";

function App() {
  useEffect(() => {
    new WOW.WOW().init();
  }, []);
  return (
    <div className="App">
      <HomeComponent />
    </div>
  );
}

export default App;
