import React, { useEffect } from 'react'
import { OurStory, VisionMission } from '../constants/constants'
import SideLayoutCards from '../components/SideLayoutCards/SideLayoutCards'
import Card from '../components/Card/Card'
// import Accordion from '../components/Accordion/Accordion';
import ScrollToTop from "react-scroll-to-top";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='container-wrapper'>
      <ScrollToTop smooth color="#6f00ff" />
      <h2 className="center mainHeading crl-white height-200">About us</h2>
      {
        OurStory.map(({ text, imgUrl, layout, title }, idx) => (
          <div key={idx} class="item-aboutus">
            <SideLayoutCards heading={title} layout={layout} paragraphs={text} image={imgUrl} />
          </div>
        ))
      }
      <div className='row container-fluid justify-content-around mar-t-100'>
        {
          VisionMission.map(({ title, text, img }) => (
            <Card key={title} title={title} text={text} img={img} />
          ))}
      </div>
      {/* <div className='row bg-white center'>
          <h2 className='heading2'>{title}</h2>
          <p>{paragraph}</p>
      </div>
      <div class="center bg-white">
        <Accordion items={items} />
      </div> */}
    </div>
  )
}

export default AboutUs