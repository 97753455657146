import React, { useEffect, useState } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Badge from "react-bootstrap/Badge";
import client1 from '../assets/client1.jpeg';
import client2 from '../assets/client2.jpeg';
import client3 from '../assets/client3.jpeg';
import client4 from '../assets/client4.jpeg';
import client5 from '../assets/client5.jpeg';



const Clients = () => {
  const [nextSlide, setnextSlide] = useState(0)
  const [current, setCurrent] = useState(0)
  // const projects = ['RKIPL Bridge', 'SV Constructions Highway', 'HK Suresh House', 'Star Constructions', 'GMN Warehouse', 'UVCE, KR Circle', 'Sequent Electricals', 'Sequent Electricals']
  const projects = [
    {
      addressLine1:"North Malabar Cancer Centre (Feasibility study, Turn key consultancy and long term Operational Management)",
      addressLine2: "Kanhangad",
    },
    {
    // img: client1,
    addressLine1: "Shihab Thangal Memorial Hospital (Commissioning of the hospital, part consultancy)",
    addressLine2: "Tirur",
    // website: "https://cardinalone.in "
  },
  {
    // img: client5,
    addressLine1: "Bharathi Hospital (Clinical vetting of the architectural layout)"    ,
    addressLine2: "Madurai",
    // website: "http://dalavaikashi.github.io/testsite/gh-pages"
  },
  {
    addressLine1: "Super Specialty Hospital project, Ponnani (Feasibility study)",
    addressLine2: "Malappuram, Kerala",
  },
  {
    addressLine1: "Benzy Polyclinic, Ponnani (Conceptualisation and design consultancy)",
    addressLine2: "Malappuram, Kerala",
  },
  {
    addressLine1: "Farah healthcare(Feasibility study for their proposed Mother and Child Hospital)",
    addressLine2: "Mangalore, Karnataka",
  },
   {
    // img: client2,
    addressLine1: "Cooperative Hospital, kattapana (Design consultancy)",
    addressLine2: "Idukki, Kerala",
    // website: "https://subrambuilders.in/"
  }, 
  {
    addressLine1: "Multi specialty hospital project (Feasibility study)",
    addressLine2: "Kelakam, Kannur Dt, Kerala"
  },
  {
   
    addressLine1: "Palghat Polyclinic Hospital (Feasibility Study, Guidance for refurbishing and recommissioning)",
    addressLine2: "Palakkad, Kerala",
    // website: "https://mrrnaturecure.com"
  }, 
  {
    addressLine1: "Dr Muthu’s Multi specialty hospital (Clinical upgradation and long term Operational Management)",
    addressLine2: "Coimbatore, Tamil Nadu "
  }
]

  const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "mobile";
    }
    return "desktop";
  };

  const isdividerRequired = (index) => {
    const deviceType = getDeviceType();
    switch (deviceType) {
      case "desktop":
        return ((nextSlide + 4) -1 !== index) 
      case "tablet":
        return !((index + 1) % Math.min(2, projects.length-(index))) == 0
      case "mobile":
        return false
      default:
        return !((index + 1) % Math.min(4, projects.length-(index))) == 0
    }
  }

  const doSpeicalThing= (currentSlide, nextSlide)=> {
    setnextSlide(nextSlide);
    setCurrent(currentSlide);
  }


  function renderProjects() {
    return projects?.map((project, index) => {
          const showDivider = isdividerRequired(index);
          console.log("showDivider", showDivider)
          return <div className='col-xs-12 col-md-4 gy-4' style={{display: 'flex', justifyContent:"center"}}>
            <div className='project-card'>
              {/* <Badge className=" font-14" bg="secondary">{project}</Badge> */}
              {/* <a target="_blank" href={project.website} > */}
              <img draggable="false" className="project-img" loading='lazy' src={process.env.PUBLIC_URL+'hospital-bgrm.png'} alt="project"/>
              {/* </a> */}
              <strong className='address'>{project.addressLine1}</strong>
              <strong  className='address'> {project.addressLine2}</strong>
            </div>
          </div>
    })
    // return <Carousel containerClass="project-card-container" 
    // itemClass='card-box-shadow'
    // responsive={responsive} renderButtonGroupOutside={true}>{
    //   projects?.map((project, index) => {
    //     const showDivider = isdividerRequired(index);
    //     console.log("showDivider", showDivider)
    //     return <div style={{display: 'flex', justifyContent:"center"}}>
    //       <div className='project-card'>
    //         {/* <Badge className=" font-14" bg="secondary">{project}</Badge> */}
    //         {/* <a target="_blank" href={project.website} > */}
    //         <img draggable="false" className="project-img" loading='lazy' src={process.env.PUBLIC_URL+'hospital-bgrm.png'} alt="project"/>
    //         {/* </a> */}
    //         <strong className='address'>{project.addressLine1}</strong>
    //         <strong  className='address'> {project.addressLine2}</strong>
    //       </div>
    //     </div>

    //   })
    // }
    // </Carousel>

  }



  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };


  return (
    <div className='container-wrapper-clients' id="projects"> 
    <h2 className="center mainHeading crl-white height-200">Our Clients</h2>
    <div className="container-xxl py-5 nav-section" >
      <div className="container">
        <div
          className="text-center wow animate__animated animate__fadeInUp"
          data-wow-delay="0.1s"
        >
              {/* <p className="d-inline-block border rounded-pill red-title py-1 px-4">WHO WE'VE EMPOWERED</p>
                        <h1 className='blue-title'>our Clientele</h1> */}
          {/* <p className="section-title text-center text-primary text-uppercase">
            WHO WE'VE EMPOWERED
          </p>
          <h1 className="mb-5">
            Our{" "}
            <span className="text-primary text-uppercase">Clientele</span>
            </h1> */}

            <div className='row justify-content-center'>{renderProjects()}</div>

      
        </div>
      </div>
    </div>
    </div>
  )
}

export default Clients;