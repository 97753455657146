import React from "react";
import styles from "./ManagementCard.module.scss";
import { SecondaryButton } from '../reusableComponents/SecondaryButton/SecondaryButton';
import { useNavigate } from "react-router-dom";

const ManagementCard = ({ data }) => {
  const { name, designation, description, navigationUrl, alignment, imageUrl, id } =
    data;
    console.log("sara", data)
  const navigate = useNavigate();
  const renderImage = () => {
    return (
      <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
        <div className="team-item position-relative rounded overflow-hidden">
          <div className="overflow-hidden card-people-img">
            <img className="img-fluid " height="300" src={imageUrl} alt="" />
          </div>
          <div className="team-text bg-light text-center p-3">
            <h5 >{name}</h5>
            <p className="mb-1">{designation}</p>
            <div className="team-social text-center">
              <SecondaryButton onClick={() => { navigate(`/profile/${id}`); }} label="View profile" />
            </div>
          </div>
        </div>
      </div>
    )
  }
  const renderLeftImage = () => {
    return (
      <div className={styles.leftContainer}>
        <div
          className={styles.imgContainer}
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + imageUrl})`,
          }}
        />
        <div className={styles.detailsContainer}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>{name}</div>
          </div>
          <div className={styles.descriptionContainer}>
            <div className={styles.description}>{description}</div>
            <div className={styles.footer}>
              <div className={styles.buttonContainer}>
                <div className={styles.memberButton} onClick>View profile</div>
              </div>
              <div className={styles.designation}>{designation}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderRightImage = () => {
    return (
      <div className={styles.rightContainer}>
        <div className={styles.detailsContainer}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>{name}</div>
          </div>
          <div className={styles.descriptionContainer}>
            <div className={styles.description}>{description}</div>
            <div className={styles.footer}>
              <div className={styles.designation}>{designation}</div>
              <div className={styles.buttonContainer}>
                <div className={styles.memberButton}>View profile</div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={styles.imgContainer}
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + imageUrl})`,
          }}
        />
      </div>
    );
  };

  return (
    renderImage()
  );
};

export default ManagementCard;
