import React, { useState } from "react";
import styles from "./Header.module.scss";
import Menu from "../../Menu/Menu";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [showMenu, toggleShowMenu] = useState(false);
  const navigate = useNavigate();
  return (
    <div className={styles.headerContainer}>
      <div className={styles.logoContainer}>
        <img
          className={styles.logo}
          onClick={e=>navigate('/')}
          src={process.env.PUBLIC_URL + "/hcslogo.png"}
          alt=""
        />
      </div>
      <div className={styles.menuWrapper}>
        <Menu />
      </div>
      {!showMenu && (
        <div
          className={styles.menuIconContainer}
          role="presentation"
          onClick={() => toggleShowMenu(!showMenu)}
        >
          <img
            className={styles.menuIcon}
            src={process.env.PUBLIC_URL + "/menu.png"}
            alt="menuPngfile"
            width={25}
            height={25}
          />
        </div>
      )}
      {showMenu && (
        <div className={styles.listMenuContainer}>
          <Menu closeMenu={() => toggleShowMenu(false)} />
        </div>
      )}
    </div>
  );
};

export default Header;
