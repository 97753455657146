import React from "react";
import PanelConsultant from "./PanelConsultant";
import Management from "../components/Management/Management";
import Accordion from '../components/Accordion/Accordion';
import { AssociatesAndPartners } from '../constants/constants'

function Teams() {
  const {title,paragraph,items} = AssociatesAndPartners;
  return (
    <div className="container-wrapper">
         <h2 className="center mainHeading crl-white height-200">Our Team</h2>
      <Management />
      <div className="bg-white mar-t-100 py-5 container-xxl mar-b-100">
        <div
          className="text-center bg-white mar-b-100 mb-5 wow animate__animated animate__fadeInUp"
          data-wow-delay="0.1s"
        >
          <h1 className="d-inline-block border red-title rounded-pill py-1 px-4 display-6">
            Panel Consultants
          </h1>
          <figcaption className="black-text mt-1">
            Meet our talented team of panel consultants
          </figcaption>
        </div>
        <PanelConsultant />
      </div>
      <div className='row bg-white center container-xxl py-2'>
          <h2 className='heading2 red-title'>{title}</h2>
          {/* <p>{paragraph}</p> */}
      </div>
      <div class="row center bg-white container-xxl mar-b-100">
        <Accordion items={items} />
      </div>
      </div>
  );
}

export default Teams;
